.head {
  position: fixed;
  top: 0;
  padding: 10px 100px;
  left: 0;
  display: flex;
  align-items: center;
  z-index: 9;
  width: 100%;
}
.head.dark {
  background: rgba(4, 10, 31, 0.8);
}
.head .logo img {
  width: 144px;
  height: 38px;
}
.head .menus {
  display: flex;
  align-items: center;
  column-gap: 50px;
  margin-left: 512px;
}
.head .menus .menu-item {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  line-height: 16px;
  flex-shrink: 0;
  transition: all 0.5s;
}
.head .menus .menu-item:hover,
.head .menus .menu-item.active {
  color: #00d4ff;
}
